#main {
    overflow: scroll;
    min-height: 100vh;
    background-color: #f9f9f9;
}

#navbar {
    background-color: #f9f9f9;
}

.dropdown-item {
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis !important;
}

.offcanvas {
    max-width: 85%;
    padding-top: 5vh
}

.progress-dot {
    top: 55%;
    width: 12px;
    height: 12px;
    position: absolute;
    border-radius: 50px;
    background-color: var(--primary);
}

.icon-wrapper {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-wrapper img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100px;
}

.icon-wrapper-lg {
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    border-radius: 150px;
    justify-content: center;
}

.icon-wrapper-lg img {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 150px;
}

.border-bottom {
    border-bottom: 0.5px solid #333 !important;
}

.due-date-card {
    border-left-width: 8px !important;
    border-left-style: solid !important;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.project-progress {
    width: 200px;
}

.project-name {
    width: calc(100% - 200px);
}

.process-card {
    width: 350px;
    margin-right: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://agavebrands.s3.us-west-1.amazonaws.com/pattern-green.png");
}

.step-card, .activity-card, .process-card {
    width: 100%;
    border: 0px;
    height: 139px;
    max-width: 350px;
    position: relative;
    display: inline-block;
}

.step-card .progress-container, .process-card .progress-container {
    position: absolute;
    bottom: 15px;
}

.step-card .metadata-container, .process-card .metadata-container {
    position: absolute;
    bottom: 25px;
}

.step-card h3, .activity-card h3, .process-card h3 {
    height: 2.8rem;
}

.step-metadata, .activity-metadata, .process-metadata {
    font-size: 12px;
}

.step-container, .activity-container, .process-container {
    max-height: 170px;
    overflow-x: scroll;
    overflow-y: hidden;
}


.step-wrapper, .activity-wrapper { 
    width: 180%;
}

.chart-wrapper {
    position: absolute;
    left: 0px;
    top: -35px;
    z-index: 0;
}

.chart-card-wrapper {
    position: absolute;
    left: -51px;
    top: -38px;
    z-index: 0;
}

.image-wrapper {
    z-index: 10;
}

.show-tablet {
    display: none !important;
}

#projects {
    height: 80vh;
    overflow-y: scroll;
}

@media(max-width: 1000px) {
    .show-tablet {
        display: inherit !important;
    }
    #main {
        margin-left: auto;
        max-width: 100%;
    }
}

@media( max-width: 767px) {
    .project-progress {
        width: 125px;
    }
    .project-name {
        width: calc(100% - 125px);
    }
        .chart-wrapper {
            left: -38px;
        }
}
