:root {
    --gray: #86938d;
    --dark: #0b4c49;
    --light: #e1e2e4;
    --primary: #f48e2f;
    --accent: #0d7575;
    --accent-light: #bcccc4;
}

input[type="radio"], input[type="checkbox"] {
    accent-color: var(--accent);
}

.hover-border-primary:hover {
    border: 1px solid var(--accent) !important;
}

#field-wrapper {
    border: 1px solid rgba(248, 249, 250, 0.5);
}
/** Border **/
.border-primary {
    border-color: var(--primary) !important;
}

.border-secondary {
    border-color: var(--accent) !important;
}

.border-dark {
    border-color: var(--dark) !important;
}

/** Background **/

.bg-black {
    background-color: #000;
}

.bg-gray {
    background-color: var(--gray);
}

.bg-dark {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-accent {
    background-color: var(--accent);
}

.bg-success {
    background-color: var(--accent) !important;
}

.bg-accent-light {
    background-color: var(--accent-light) !important;
}


/** Text **/

.text-dark {
    color: var(--dark) !important;
}

.text-accent {
    color: var(--accent);
}

.text-primary {
    color: var(--primary) !important;
}

/** Buttons */
.btn-primary:disabled {
    background-color: var(--primary);
    color: var(--dark);
    opacity: 0.7;
}

.btn-primary {
    background-color: var(--primary);
    color: black
}

.btn-primary:focus {
    background-color: var(--accent);
    box-shadow: none;
}

.btn-primary:hover {
    color: var(--primary);
    background-color: var(--dark);
}

.btn-primary:active, .btn:first-child:active {
    background-color: var(--primary);
    color: var(--dark);
}


.btn-clear:focus {
    background-color: transparent !important;
    border: 0px;
    box-shadow: none;
}

.btn-clear:hover {
    color: var(--primary);
    border: 0px;
    background-color: transparent !important;
}

.btn-clear:active,
.btn:first-child:active {
    background-color: transparent !important;
    color: var(--dark);
    border: 0px;
}

.btn-secondary {
    background-color: var(--accent) !important;
    color: white !important;
    border: 0px;
}

.btn-outline-accent {
    border-color: var(--accent);
    color: var(--accent);
}

.btn-outline-accent:disabled {
    border-color: var(--accent);
    color: var(--accent);
}

.btn-outline-accent:hover {
    background-color: var(--primary);
    border: var(--primary);
}

.btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
}

.btn-outline-primary:disabled {
    border-color: var(--primary);
    color: var(--primary);
}

.btn-outline-primary:hover {
    background-color: var(--accent);
    border: var(--accent);
}

.btn-light {
    background-color: var(--accent-light);
}

/** Forms **/

.form-control:focus, .form-control:focus-within, .form-control:active {
    box-shadow: none;
    border-color: var(--accent)
}

.form-check-input:checked {
    background-color: var(--accent);
}

/** General **/


.menu-category:hover {
    background-color: var(--accent);
}

.menu-category:active {
    box-shadow: none;
    background-color: var(--accent);
}

.menu-category:focus {
    box-shadow: none;
    background-color: var(--accent);
}

.nav-item:hover {
    background-color: var(--accent);
    color: #000;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--accent);
    color: #fff;
}

.user {
    background-color: var(--accent);
}

.nav-item .mobile:focus {
    background-color: var(--accent);
}

.image-input-container {
    border: 2px solid var(--accent);
}

.user-menu {
    background-color: var(--gray);
}


.hover-light:hover {
    color: var(--dark) !important;
    background-color: var(--light);
}

.status-badge {
    background-color: var(--accent-light);
}

/* Ionic */

.ion-color-accent {
    background-color: var(--accent-light) !important;
    padding: 4px 6px;
}

.accordion-button:not(.collapsed) {
    color: var(--dark);
    background-color: var(--accent-light);
}

.accordion-button:focus, .accordion-button:focus-within, .accordion-button:focus-visible {
    box-shadow: 0 0 0 0.25rem var(--light) !important;
    border-color: var(--light) !important;
}