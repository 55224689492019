@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;700&display=swap');

@font-face {
    font-family: "Lucida Bright";
    src: url("https://agavebrands.s3.us-west-1.amazonaws.com/LBRITED.woff") format("woff")
}

h1, h2, .h1, .h2, .lucida {
    font-family: 'Lucida Bright' !important;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.4em;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 400
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6,
p,
div,
a,
input,
body {
    font-family: "Epilogue", sans-serif !important;
}

p,
div,
a,
input,
body,
span {
    font-size: 0.9rem;
}

h2 {
    font-size: 1.2rem;
}

h3 {
    font-size: 1.1rem;
}

h4 {
    font-weight: 600;
    font-size: 0.9rem;
}

p,
div,
a,
input,
body {
    font-weight: 300;
}

.display-2, .display-3, .display-4, .display-5 {
    font-weight: 700;
}

.font-header {
    font-family: 'Lucida Bright' !important;
}

