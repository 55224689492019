@import url(https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;700&display=swap);
:root {
    --gray: #86938d;
    --dark: #0b4c49;
    --light: #e1e2e4;
    --primary: #f48e2f;
    --accent: #0d7575;
    --accent-light: #bcccc4;
}

input[type="radio"], input[type="checkbox"] {
    accent-color: var(--accent);
}

.hover-border-primary:hover {
    border: 1px solid var(--accent) !important;
}

#field-wrapper {
    border: 1px solid rgba(248, 249, 250, 0.5);
}
/** Border **/
.border-primary {
    border-color: var(--primary) !important;
}

.border-secondary {
    border-color: var(--accent) !important;
}

.border-dark {
    border-color: var(--dark) !important;
}

/** Background **/

.bg-black {
    background-color: #000;
}

.bg-gray {
    background-color: var(--gray);
}

.bg-dark {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-accent {
    background-color: var(--accent);
}

.bg-success {
    background-color: var(--accent) !important;
}

.bg-accent-light {
    background-color: var(--accent-light) !important;
}


/** Text **/

.text-dark {
    color: var(--dark) !important;
}

.text-accent {
    color: var(--accent);
}

.text-primary {
    color: var(--primary) !important;
}

/** Buttons */
.btn-primary:disabled {
    background-color: var(--primary);
    color: var(--dark);
    opacity: 0.7;
}

.btn-primary {
    background-color: var(--primary);
    color: black
}

.btn-primary:focus {
    background-color: var(--accent);
    box-shadow: none;
}

.btn-primary:hover {
    color: var(--primary);
    background-color: var(--dark);
}

.btn-primary:active, .btn:first-child:active {
    background-color: var(--primary);
    color: var(--dark);
}


.btn-clear:focus {
    background-color: transparent !important;
    border: 0px;
    box-shadow: none;
}

.btn-clear:hover {
    color: var(--primary);
    border: 0px;
    background-color: transparent !important;
}

.btn-clear:active,
.btn:first-child:active {
    background-color: transparent !important;
    color: var(--dark);
    border: 0px;
}

.btn-secondary {
    background-color: var(--accent) !important;
    color: white !important;
    border: 0px;
}

.btn-outline-accent {
    border-color: var(--accent);
    color: var(--accent);
}

.btn-outline-accent:disabled {
    border-color: var(--accent);
    color: var(--accent);
}

.btn-outline-accent:hover {
    background-color: var(--primary);
    border: var(--primary);
}

.btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
}

.btn-outline-primary:disabled {
    border-color: var(--primary);
    color: var(--primary);
}

.btn-outline-primary:hover {
    background-color: var(--accent);
    border: var(--accent);
}

.btn-light {
    background-color: var(--accent-light);
}

/** Forms **/

.form-control:focus, .form-control:focus-within, .form-control:active {
    box-shadow: none;
    border-color: var(--accent)
}

.form-check-input:checked {
    background-color: var(--accent);
}

/** General **/


.menu-category:hover {
    background-color: var(--accent);
}

.menu-category:active {
    box-shadow: none;
    background-color: var(--accent);
}

.menu-category:focus {
    box-shadow: none;
    background-color: var(--accent);
}

.nav-item:hover {
    background-color: var(--accent);
    color: #000;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--accent);
    color: #fff;
}

.user {
    background-color: var(--accent);
}

.nav-item .mobile:focus {
    background-color: var(--accent);
}

.image-input-container {
    border: 2px solid var(--accent);
}

.user-menu {
    background-color: var(--gray);
}


.hover-light:hover {
    color: var(--dark) !important;
    background-color: var(--light);
}

.status-badge {
    background-color: var(--accent-light);
}

/* Ionic */

.ion-color-accent {
    background-color: var(--accent-light) !important;
    padding: 4px 6px;
}

.accordion-button:not(.collapsed) {
    color: var(--dark);
    background-color: var(--accent-light);
}

.accordion-button:focus, .accordion-button:focus-within, .accordion-button:focus-visible {
    box-shadow: 0 0 0 0.25rem var(--light) !important;
    border-color: var(--light) !important;
}
#main {
    overflow: scroll;
    min-height: 100vh;
    background-color: #f9f9f9;
}

#navbar {
    background-color: #f9f9f9;
}

.dropdown-item {
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis !important;
}

.offcanvas {
    max-width: 85%;
    padding-top: 5vh
}

.progress-dot {
    top: 55%;
    width: 12px;
    height: 12px;
    position: absolute;
    border-radius: 50px;
    background-color: var(--primary);
}

.icon-wrapper {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-wrapper img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100px;
}

.icon-wrapper-lg {
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    border-radius: 150px;
    justify-content: center;
}

.icon-wrapper-lg img {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 150px;
}

.border-bottom {
    border-bottom: 0.5px solid #333 !important;
}

.due-date-card {
    border-left-width: 8px !important;
    border-left-style: solid !important;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.project-progress {
    width: 200px;
}

.project-name {
    width: calc(100% - 200px);
}

.process-card {
    width: 350px;
    margin-right: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://agavebrands.s3.us-west-1.amazonaws.com/pattern-green.png");
}

.step-card, .activity-card, .process-card {
    width: 100%;
    border: 0px;
    height: 139px;
    max-width: 350px;
    position: relative;
    display: inline-block;
}

.step-card .progress-container, .process-card .progress-container {
    position: absolute;
    bottom: 15px;
}

.step-card .metadata-container, .process-card .metadata-container {
    position: absolute;
    bottom: 25px;
}

.step-card h3, .activity-card h3, .process-card h3 {
    height: 2.8rem;
}

.step-metadata, .activity-metadata, .process-metadata {
    font-size: 12px;
}

.step-container, .activity-container, .process-container {
    max-height: 170px;
    overflow-x: scroll;
    overflow-y: hidden;
}


.step-wrapper, .activity-wrapper { 
    width: 180%;
}

.chart-wrapper {
    position: absolute;
    left: 0px;
    top: -35px;
    z-index: 0;
}

.chart-card-wrapper {
    position: absolute;
    left: -51px;
    top: -38px;
    z-index: 0;
}

.image-wrapper {
    z-index: 10;
}

.show-tablet {
    display: none !important;
}

#projects {
    height: 80vh;
    overflow-y: scroll;
}

@media(max-width: 1000px) {
    .show-tablet {
        display: inherit !important;
    }
    #main {
        margin-left: auto;
        max-width: 100%;
    }
}

@media( max-width: 767px) {
    .project-progress {
        width: 125px;
    }
    .project-name {
        width: calc(100% - 125px);
    }
        .chart-wrapper {
            left: -38px;
        }
}

html {
  width: 100vw;
  max-width: 100vw;
}


#root {
  width: 100vw;
  height: 100vh;
  padding: 0px;
  overflow-y: hidden;
}

.project-img {
  height: 80px;
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  object-fit: cover;
  border-radius: 160px;
}

.react-pdf__Page canvas {
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
}

.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf__Page__annotations {
  display: none !important;
}

img.round {
  border-radius: 50%;
}

.badge {
  font-size: 12px
}

.user-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  visibility: hidden;
}

.app-link {
  cursor: pointer;
  position: relative;
}

.user-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  object-fit: cover;
}

.home-row {
  height: 50vh;
}

.home-img {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-icon {
  max-width: 70px;
}

.transfer-deposit {
  max-height: 400px;
  object-fit: contain;
  object-position: left;
}

.alert {
  z-index: 1000;
}


.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.panel-header-logo {
  max-width: 65px;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  position: absolute;
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.text-large {
  font-size: 1em;
}

.carousel-inner {
  border-radius: 10px;
}

.carousel-img {
  width: 100%;
  border-radius: 10px;
  max-height: 50vh;
  object-fit: cover;
}

.class-container {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-col {
  width: calc(100% / 7);
}

.schedule-container {
  width: 170%;
}

.br-10 {
  border-radius: 10px;
}

.video-panel {
  position: relative;
}

.video-panel-title {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.video-panel-overlay {
  background-color: #000;
  position: absolute;
  height: 50vh;
  opacity: 0.5;
  width: 100%;
  left: 0px;
  top: 0px;
}

.video-panel-img {
  height: 50vh;
  object-fit: cover;
}

.swipe-container {
  max-width: 100%;
  display: flex;
  overflow-x: scroll;
  transition: all 0.5s;
  scroll-behavior: smooth;
  margin-bottom: 16px;
}

.swipe-container::-webkit-scrollbar {
  display: none;
}

.day-card {
  min-width: calc(100vw / 7);
}

.day-card .card {
  min-height: 100px;
  background-color: transparent;
}

.card {
  padding: 24px;
  border-radius: 8px;
  transition: all 0.25s;
}

.card:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.head-coach-ribbon {
  position: absolute;
  font-weight: 800;
  color: #000;
  left: 0px;
  top: 20px;
  width: 30px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.slideshow-logo {
  max-width: 450px;
}

.slideshow-img {
  height: 100vh;
  object-fit: cover;
}

.slideshow-text {
  position: absolute;
  top: 25%;
  padding: 16px;
  z-index: 10;
  max-width: 600px;
}

.z-10 * {
  z-index: 10;
}

.hero-content {
  max-width: 600px;
}

.carousel-overlay {
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 1) 75%,
      rgba(255, 255, 255, 0) 150%);
  opacity: 0.5;
  position: absolute;
  display: block;
  height: 80vh;
  width: 100%;
  top: 0;
}

.btn {
  font-size: 14px;
  font-weight: 300 !important;
}

.btn.btn-sm {
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 25px;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.btn-primary {
  border: 0px;
}

.menu-category {
  font-size: 1.2rem;
  width: 100%;
}


.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}


.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
}

.navbar {
  background: white;
  position: fixed;
  z-index: 200;
  width: 100%;
  top: 0px;
  left: 0;
}

#navbar {
  max-width: calc(100vw - 300px);
  margin-left: 300px;
  overflow: hidden;
}

.nav-item {
  height: 60px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  font-weight: 800;
}

.navbar-cta {
  margin-top: 10px;
  transition: all 0.25s;
}

.navbar-cta:hover {
  transform: scale(1.05);
}


.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  display: inline-block;
  color: inherit;
}

.nav-link:hover {
  color: inherit;
}

.btn-round {
  border-radius: 25px !important;
}

.btn:not(.btn-sm) {
  border-radius: 25px;
  padding: 12px 24px;
  font-weight: 800;
}

.logo-landing {
  max-width: 300px;
}

.text-funny {
  font-size: 4rem;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  left: 0;
  width: 160px;
  color: #fff;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  padding-top: 0px;
  margin-left: 300px;
  height: calc(100vh - 55px);
  max-width: calc(100% - 300px);
}

.dropdown-item {
  color: #fff;
}


.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.cursor-default {
  cursor: default;
}

.offcanvas * {
  z-index: 1045;
}

.profile-image {
  max-width: 300px;
}

.video-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh
}

.thumbnail {
  max-width: 16px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.video-thumbnail {
  height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
}

.card-video-title {
  font-weight: 800;
}

.card-video {
  border-radius: 10px;
  height: 350px;
  margin-bottom: 32px;
  padding-bottom: 16px;
  color: #fff;
}

.card-video:hover {
  transform: scale(1.1);
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.banner-bg {
  padding-top: 150px;
  padding-left: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
}

.filter-card {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.user-name {
  font-size: 20px;
}

.user {

  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}


.home-video {
  height: 90vh;
  object-fit: cover;
}

#video-row {
  margin-top: 60px !important;
}

.logo-navbar {
  max-width: 100px;
}

.trailer {
  max-width: 1100px;
  width: 100%;
}


.mvh-100 {
  min-height: 100vh;
}

.text-banner {
  animation: animate 60s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0%);
  }
}

#main-landing {
  overflow-x: hidden;
  max-width: 100vw;
}

.login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-scale {
  transform: none !important;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.user-menu {
  margin-left: -22%;
  color: #fff;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
}

.br-0 {
  border-radius: 0px !important;
}

.fixed-bottom {
  z-index: 10;
}

.bottom-navbar {
  position: fixed;
  z-index: 10;    
  bottom: 0px;
  width: 100%;
}

.bottom-navbar i {
  font-size: 24px;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.list-style-none {
  list-style-type: none;
  padding-left: 0px;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.border-0 .item-inner { 
  border-bottom-width: 0px !important;
}

.bold {
  font-weight: 800;
}

.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.h-auto {
  height: auto;
}

.background-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.strikethrough {
  text-decoration: line-through;
}

.coach-home-img {
  height: 400px;
  object-fit: cover;
}

.black-overlay {
  background-color: #000;
  display: block;
  opacity: 0.4;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
}

.btn-bottom {
  position: fixed;
  width: 100%;
  margin: auto;
  bottom: 15px;
  max-width: 1400px;
}

label, span, input, .btn-sm {
  font-size: 16px;
}

.show-mobile {
  display: none !important;
}

.bg-vertical {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

div.app-landing {
  width: 100%;
  position: absolute;
  padding-bottom: 4rem !important;
  bottom: 0px;
}

.app-landing .container {
  max-width: 550px;
}

.comment-input {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 170px;
}

.comment-feed {
  height: 70vh;
  overflow-y: scroll;
}

.time-left {
  padding-right: 0.65rem;
  padding-left: 0.65rem;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 28px;
}

#scroll-text {
  /* animation properties */
  transform: translateX(100%);
  animation: text-scroll 15s linear infinite;
}

.container.checkout {
  min-height: 82vh;
}

.ElementsApp .InputElement, .ElementsApp .InputElement::placeholder  {
  color: #000 !important;
}

.sidebar {
  overflow-y: scroll;
  position: fixed;
  height: 100vh;
  width: 300px;
  left: 0px;
  top: 0px;
}

.content {
  margin: auto;
  min-height: 100vh;
  max-width: 1200px;
}

.container {
  max-width: 800px;
}

.fixed-bottom.container {
  max-width: 200px;
  margin-right: 0px;
}

#auth-form {
  max-width: 500px;
  margin: auto;
}

/* for Firefox */

/* for Chrome */

@keyframes text-scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}


@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
  .class-type-card {
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
  .main-panel {
    margin-top: 45px;  
  }
}


@media (max-width: 768px) {
  .pt-notch {
    padding-top: 84px !important;
  }
  .comment-input {
    left: 0px;
  }
  .fixed-bottom.container {
    max-width: 100%;
    margin-right: auto;  
  }
  .alert.fixed-top {
    margin-top: 64px;
  }
  .display-1 {
    font-size: 2.8rem !important;
  }
  h2.h3 {
    font-size: 20px !important;
  }
  .content {
    max-width: 100vw;
    margin-left: 0px;
  }
  .bottom-navbar {
    display: block;
  }
  .container-fluid.p-5 {
    padding: 12px !important;
  }
  .app-link-overlay {
    height: 25vh;
  }
  .home-img {
    height: 50%;
  }
  .schedule-mobile-container {
    overflow-x: scroll;
  }
  .schedule-mobile-wrapper {
    width: 350%;
  }
  .show-mobile {
    display: block;
  }
  .schedule-col {
    width: calc(100% / 7);
    margin-top: 32px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-bottom: 80px;
    padding-top: 32px;    
    margin-left: 0px;
    max-width: 100vw;
  }
  .text-funny {
    font-size: 32px;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .slideshow-logo {
    max-width: 250px;
  }
  .latest-card-content {
    width: 100%;
  }
  .latest-card-overlay {
     width: 100%; 
  }
  .mobile-center {
    text-align: center;
  }
  .carousel-caption {
    bottom: 20% !important;
  }
  .carousel-caption .display-2 {
    font-size: 2rem;
  }
  #hero h1 {
    font-size: 5em;
  }
  #navbar {
    max-width: 100vw;
    margin-left: 0px;
  }
}

@font-face {
    font-family: "Lucida Bright";
    src: url("https://agavebrands.s3.us-west-1.amazonaws.com/LBRITED.woff") format("woff")
}

h1, h2, .h1, .h2, .lucida {
    font-family: 'Lucida Bright' !important;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.4em;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 400
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6,
p,
div,
a,
input,
body {
    font-family: "Epilogue", sans-serif !important;
}

p,
div,
a,
input,
body,
span {
    font-size: 0.9rem;
}

h2 {
    font-size: 1.2rem;
}

h3 {
    font-size: 1.1rem;
}

h4 {
    font-weight: 600;
    font-size: 0.9rem;
}

p,
div,
a,
input,
body {
    font-weight: 300;
}

.display-2, .display-3, .display-4, .display-5 {
    font-weight: 700;
}

.font-header {
    font-family: 'Lucida Bright' !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.input-group {
    border: 0.5px solid rgba(0,0,0,0.1);
    border-radius: 10px !important;
}

.input-group .form-control {
    border: 0px;
    border-radius: 0px 10px 10px 0px !important;
}

.input-group-text {
    border: 0px;
    border-radius: 10px;
    background-color: white;
}

.form-control {
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.form-control.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.form-control.py-0 {
    padding-top: 0px !important;
        padding-bottom: 0px !important;
}
:root {
    --gray: #86938d;
    --dark: #0b4c49;
    --light: #e1e2e4;
    --primary: #f48e2f;
    --accent: #0d7575;
    --accent-light: #bcccc4;
    --ion-color-primary: var(--primary) !important;
    --background-checked: var(--primary) !important;
}

.checkbox-checked .checkbox-icon {
    border-color: var(--primary) !important;
    background:var(--primary) !important;
}

ion-content {
    height: auto !important;
    min-height: 100vh !important;
    padding: 80px 0px !important;
}

ion-segment {
    border-radius: 15px;
}

ion-segment-button {
    border-radius: 10px;
}

ion-segment-button ion-label {
    margin-top: 0px;
    margin-bottom: 0px; 
}

.ion-color-primary {
    --ion-color-base: var(--primary) !important;
    --ion-color-base-rgb: var(--primary) !important;
    --ion-color-contrast: var(--primary) !important;
    --ion-color-contrast-rgb: var(--primary) !important;
    --ion-color-shade: var(--primary) !important;
    --ion-color-tint: var(--primary) !important;
}

:host {
    --background-checked: var(--primary, --primary) !important;
    --border-color-checked: var(--primary, --primary) !important;
    --ion-color-primary: var(--primary) !important;
}


ion-list {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}
