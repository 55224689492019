:root {
    --gray: #86938d;
    --dark: #0b4c49;
    --light: #e1e2e4;
    --primary: #f48e2f;
    --accent: #0d7575;
    --accent-light: #bcccc4;
    --ion-color-primary: var(--primary) !important;
    --background-checked: var(--primary) !important;
}

.checkbox-checked .checkbox-icon {
    border-color: var(--primary) !important;
    background:var(--primary) !important;
}

ion-content {
    height: auto !important;
    min-height: 100vh !important;
    padding: 80px 0px !important;
}

ion-segment {
    border-radius: 15px;
}

ion-segment-button {
    border-radius: 10px;
}

ion-segment-button ion-label {
    margin-top: 0px;
    margin-bottom: 0px; 
}

.ion-color-primary {
    --ion-color-base: var(--primary) !important;
    --ion-color-base-rgb: var(--primary) !important;
    --ion-color-contrast: var(--primary) !important;
    --ion-color-contrast-rgb: var(--primary) !important;
    --ion-color-shade: var(--primary) !important;
    --ion-color-tint: var(--primary) !important;
}

:host {
    --background-checked: var(--primary, --primary) !important;
    --border-color-checked: var(--primary, --primary) !important;
    --ion-color-primary: var(--primary) !important;
}


ion-list {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}