/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.input-group {
    border: 0.5px solid rgba(0,0,0,0.1);
    border-radius: 10px !important;
}

.input-group .form-control {
    border: 0px;
    border-radius: 0px 10px 10px 0px !important;
}

.input-group-text {
    border: 0px;
    border-radius: 10px;
    background-color: white;
}

.form-control {
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.form-control.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.form-control.py-0 {
    padding-top: 0px !important;
        padding-bottom: 0px !important;
}